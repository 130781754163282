<template>
  <div class = "container">
    
    <div class="row grey darken-2 marginTop" v-if="isSuccess">
      <Success title="Reseña Enviada!" @goBack="goBack">
        <ReviewSuccess />
      </Success>
    </div>

    <div class="row grey darken-1 marginTop" v-else>

        <div class="col s12 center orange">
            <h5 class="white-text">Reseña</h5>
        </div>

        <LateralNav />

        <div class="col s12 l8 grey darken-2 center center">
          <p class = "grey-text white-text left-align">
            Comparta con los demas usuarios su experiencia en <b>QBita</b> con el usuario <b>{{ alias }}</b> en la compraventa <b>{{ escrow }}</b>.
          </p>

          <div class="col s12 white dividerLine"></div>

          <Stars
            :isBig="true"
            :score="score.userRating"
            parameter="userRating"
            :isForm="true"
            @evaluated="handleEvaluation"
          >
            <h6 class="orange-text left-align">Evalue el usuario {{ alias }}</h6>
            <p class="white-text left-align">
              (1 Estrella = Nada serio || 5 Estrellas = Muy serio)
            </p>
          </Stars>

          <Stars
            :isBig="true"
            :score="score.qbitaRating"
            parameter="qbitaRating"
            :isForm="true"
            @evaluated="handleEvaluation"
          >
            <h6 class="orange-text left-align">Evalue el Exchange QBita</h6>
            <p class = "white-text left-align">
              (1 Estrella = Lo odio || 5 Estrellas = Lo amo)
            </p>
          </Stars>

          <Stars
            :isBig="true"
            :score="score.escrowRating"
            parameter="escrowRating"
            :isForm="true"
            @evaluated="handleEvaluation"
          >
            <h6 class="orange-text left-align">Evalue la sencillez del Proceso de Compraventa</h6>
            <p class = "white-text left-align">(1 Estrella = Muy complicado || 5 Estrellas = Muy fácil)</p>
          </Stars>

          <h6 class = 'orange-text left-align'>
            Cuentenos
          </h6>
          <p class ="white-text left-align">
            Describa su experiencia con el usuario <b class = 'orange-text'>{{ alias }}</b>?
          </p>
          <div class = 'input-field'>
            <textarea id='reviewMessage' class='materialize-textarea' v-model="message" data-length='500'></textarea>
            <label for='reviewMessage'><i class='fas fa-pen'></i> Texto</label>
          </div>

          <div class="col s12 center">
            <Loader v-if="isLoading" />
            <Error :warning="error" v-if="error"/>
          </div>

          <div class="col s12 center">
            <LargeButton 
              icon="fas fa-star"
              color="green"
              text="Enviar"
              position="left"
              @click="handleClick"
            />
          </div>
            
        </div>

        
    </div>

  </div>

</template>

<script>

import Loader from '@/components/UI/Loader'
import Error from '@/components/UI/Error'
import LateralNav from '@/components/nav/LateralNav'
import LargeButton from '@/components/UI/Buttons/LargeButton'
import Stars from '@/components/Reputation/Stars'
import ReviewSuccess from '@/components/Reputation/ReviewSuccess'
import { ref } from '@vue/reactivity'
import { post } from '@/composables/httpCalls'
import Success from '@/components/UI/Success'
import useBoilerplate from '@/composables/useBoilerplate';

export default {
  
  components: { LateralNav, Loader, Error, Success, Stars, LargeButton, ReviewSuccess },
  props: ['alias', 'escrow'],
  setup(props){
    
    const { router, isLoading, error, isSuccess } = useBoilerplate(false);
    
    const message = ref('');

    const score = ref({
        qbitaRating: 0,
        userRating: 0,
        escrowRating: 0,
    })

    const goBack = () => {
      router.push({name: "Escrow", params: {id: props.escrow}});
    }
   
    const handleEvaluation = obj => {
      score.value[obj.parameter] = obj.evaluation;
    }

    const handleClick = async () => {
      
      if(score.value.qbitaRating == 0 || score.value.userRating == 0 || score.value.escrowRating == 0){
        return error.value = "Es Necesario Evaluar Todos los Parametros";
      }

      if(message.value.length < 1){
        return error.value = "Es Necesario Describir su Experiencia";
      }

      isLoading.value = true;
      error.value = null;

      const vars = {
        ...score.value,
        text: message.value,
        reviewUser: props.alias,
        reviewEscrow: props.escrow,        
      }

      const url = "/api/newReview"
      
      try {
        
        const res = await post(url, vars);

        if(res.status == 200){
          isSuccess.value = true;
        }else{
          error.value = "Error";
          return isLoading.value = false;
        }

      } catch (err) {
        console.log(err);
        error.value = "No Se Pudo Enviar la Evaluacion"
        return isLoading.value = false;
      }
    }

    return {  error, isLoading, isSuccess,
              message, handleEvaluation, 
              handleClick, goBack, score 
          }

  }

}

</script>

<style scoped>
    textarea{
      color: white !important;
    }
    b{
      color: orange;
    }
    .dividerLine{
      height: 2px;
      margin-bottom: 20px;
    }
</style>