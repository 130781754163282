<template>
    <h1 class = "margin">
        <i class="fas fa-star grey-text"></i>
    </h1>
    <h6 class="white-text">
        Su Opinion va a ayudar a toda la Comunidad
    </h6>
</template>

<style scoped>
    .fa-star{
        font-size: 120px;
    }
</style>